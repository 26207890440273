import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AnimalCustomFieldDto,
  AnimalDentitionDto,
  AnimalDetailDto,
  AnimalHipHeightDto,
  AnimalHistoryDto,
  AnimalLotDto,
  AnimalMessageDto,
  AnimalPenDto,
  AnimalProcedureDto,
  EventType
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class AnimalDetailsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getAnimaldDentitions(operationId: string, animalId: string): Observable<AnimalDentitionDto[]> {
    return this.http.get<AnimalDentitionDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/Dentitions`);
  }

  getAnimalDetail(operationId: string, animalId: string): Observable<AnimalDetailDto> {
    return this.http.get<AnimalDetailDto>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/Detail`);
  }

  getAnimalProcedures(operationId: string, animalId: string): Observable<AnimalProcedureDto[]> {
    return this.http.get<AnimalProcedureDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/Procedures`);
  }

  getAnimalLots(operationId: string, animalId: string): Observable<AnimalLotDto[]> {
    return this.http.get<AnimalLotDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/Lots`);
  }

  getAnimalMessages(operationId: string, animalId: string): Observable<AnimalMessageDto[]> {
    return this.http.get<AnimalMessageDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/Messages`);
  }

  getAnimalHipHeights(operationId: string, animalId: string): Observable<AnimalHipHeightDto[]> {
    return this.http.get<AnimalHipHeightDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/HipHeight`);
  }

  getAnimalPens(operationId: string, animalId: string): Observable<AnimalPenDto[]> {
    return this.http.get<AnimalPenDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/Pens`);
  }

  getAnimalEventsHistory(operationId: string, animalId: string, showPending = true): Observable<AnimalHistoryDto> {
    const params = new HttpParams().set('showPendingEvents', showPending);
    return this.http.get<AnimalHistoryDto>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/History`, { params });
  }

  getAnimalCustomFields(operationId: string, animalId: string): Observable<AnimalCustomFieldDto[]> {
    return this.http.get<AnimalCustomFieldDto[]>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/CustomFields`);
  }

  deleteAnimalEvent(operationId: string, animalId: string, eventId: string, eventType: EventType): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Details/${animalId}/DeleteEvent/${eventType}/${eventId}`);
  }

  deleteAnimal(operationId: string, animalId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Feedlot/${operationId}/Animals/Animal/${animalId}`);
  }
}
