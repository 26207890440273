import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';
import { AnimalsPermissionList, EventsPermissionList, LotsPermissionList, PensPermissionList, ReportsPermissionList, WorkOrdersPermissionList } from '@ifhms/feedlot/shared/domain/permissions';

export const getFeedlotDesktopNavigationMenuItems = (feedlotName: string): NavListBaseItem[] => ([
  {
    key: 'pen-map',
    icon: 'map',
    active: false,
    isVisible: false,
    routerLink: [`/feedlot/${feedlotName}/pen-map`]
  },
  {
    key: 'work-orders',
    icon: 'summarize',
    active: false,
    isVisible: false,
    routerLink: [`/feedlot/${feedlotName}/work-orders`],
    permissionList: WorkOrdersPermissionList
  },
  {
    key: 'events',
    icon: 'sd_card_alert',
    active: false,
    isVisible: true,
    routerLink: [`/feedlot/${feedlotName}/events`],
    permissionList: EventsPermissionList
  },
  {
    key: 'animals',
    customIcon: 'animal',
    active: false,
    isVisible: true,
    routerLink: [`/feedlot/${feedlotName}/animals/overview`],
    permissionList: AnimalsPermissionList
  },
  {
    key: 'lots',
    icon: 'grid_view',
    active: false,
    isVisible: false,
    routerLink: [`/feedlot/${feedlotName}/lots`],
    permissionList: LotsPermissionList
  },
  {
    key: 'pens',
    icon: 'fence',
    active: false,
    isVisible: false,
    routerLink: [`/feedlot/${feedlotName}/pens`],
    permissionList: PensPermissionList
  },
  {
    key: 'reports',
    icon: 'insert_chart',
    active: false,
    isVisible: false,
    routerLink: [`/feedlot/${feedlotName}/reports`],
    permissionList: ReportsPermissionList
  },
  {
    key: 'settings',
    icon: 'settings',
    isVisible: false,
    active: false,
    routerLink: [`/feedlot/${feedlotName}/settings`]
  }
])
