import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-switch',
  templateUrl: './switch-type.component.html',
  styleUrls: ['./switch-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {

  label: string;

  ngOnInit(): void {
    this.label = this.props['switchLabel'];
  }

  // TODO: probably just remove this logic
  onChange($event: any): void {
    console.log($event)
  }
}
