import { Inject, Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { AH_FMS_UPC_CONFIG, AhFmsUPCConfig } from '../tokens';

@Injectable({ providedIn: 'root' })
export class UpcApplicationSettingsService {
  public hubConnection: HubConnection | null;
  private _connectionState = new BehaviorSubject<HubConnectionState>(
    HubConnectionState.Disconnected
  );
  connectionState$ = this._connectionState.asObservable();
  public upcClientId$ = new Subject<string>();

  constructor(
    @Inject(AH_FMS_UPC_CONFIG) private upcSignalRConfig: AhFmsUPCConfig
  ) {}

  private appSettingsInitialized: boolean | null = null;

  // This class is to detect if a upc client is registered
  // This is not init device
  async initDevice(url?: string): Promise<boolean> {
    if (!this.appSettingsInitialized) {
      this.appSettingsInitialized = await this.initializeDevice(url);
      if (this.appSettingsInitialized) {
        const clientId = await this.getUpcClientId();
        this.upcClientId$.next(clientId);
      }
    }

    return this.appSettingsInitialized;
  }

  private async initializeDevice(url?: string): Promise<boolean> {
    this.hubConnection = this.createConnection(url);

    await this.startConnection();

    if (this.hubConnection.state === HubConnectionState.Connected) {
      this._connectionState.next(HubConnectionState.Connected);
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  protected createConnection(url?: string): HubConnection {
    const connectionUrl: string = url || this.upcSignalRConfig.upcSignalRUrl;

    return new HubConnectionBuilder()
      .withUrl(`${connectionUrl}/AppSettings`)
      .configureLogging(LogLevel.None) //TODO: connect this to a custom logger output
      .build();
  }

  protected async startConnection(): Promise<void> {
    if (!this.hubConnection) return;
    try {
      await this.hubConnection.start();
    } catch (error) {
      // For now, we'll just silently fail without logging to the console
    }
    this.hubConnection.onclose((error) => {
      console.log('AppSettings connection closed:', error);
      this._connectionState.next(HubConnectionState.Disconnected);
    });
  }

  async getUpcClientId(): Promise<string> {
    if (!this.hubConnection) {
      throw new Error('Hub connection is not established.');
    }
    try {
      const clientId = await this.hubConnection.invoke<string>('GetClientId');
      return clientId;
    } catch (err) {
      console.error(
        'Error getting client ID. Please ensure your UPC client is running.',
        err
      );
      throw err;
    }
  }
}
