import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  GoToEventDto,
  TransferInWorkOrderDto,
  TransferInWorkOrderUpdateDto,
  WorkOrderTransferInListDto,
  WorkOrderTransferInListRequestDto,
  WorkOrderTransferInStatus
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class TransferInWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  // TODO: Update endpoints to TransferIn

  getList(feedlotId: string, request: WorkOrderTransferInListRequestDto): Observable<WorkOrderTransferInListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderTransferInListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder`, {
      params
    });
  }

  getTransferInWorkOrder(feedlotId: string, workOrderId: string): Observable<TransferInWorkOrderDto> {
    return this.http.get<TransferInWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}`);
  }

  update(feedlotId: string, transferOutWorkOrderUpdate: TransferInWorkOrderUpdateDto): Observable<TransferInWorkOrderDto> {
    return this.http.put<TransferInWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder`, transferOutWorkOrderUpdate);
  }

  schedule(feedlotId: string, workOrderId: string): Observable<TransferInWorkOrderDto> {
    return this.http.put<TransferInWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}/Schedule`, null);
  }

  requestApproval(feedlotId: string, workOrderId: string, workEventDate: string): Observable<TransferInWorkOrderDto> {
    return this.http.put<TransferInWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}/RequestApproval`, { workEventDate });
  }

  generateReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/TransferInWorkOrder`, options);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<TransferInWorkOrderDto> {
    return this.http.put<TransferInWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}/Finalize`, null);
  }

  complete(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Complete`, null);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<TransferInWorkOrderDto> {
    return this.http.delete<TransferInWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}`);
  }

  getActiveEventId(feedlotId: string, workOrderId: string): Observable<GoToEventDto> {
    return this.http.get<GoToEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/GoToEvent`);
  }

  private getRequestStatusName(status: WorkOrderTransferInStatus): string {
    switch (status) {
      case WorkOrderTransferInStatus.Scheduled:
        return 'Schedule';
      case WorkOrderTransferInStatus.WaitingForApproval:
        return 'RequestApproval';
      case WorkOrderTransferInStatus.Finalized:
        return 'Finalize';
      default:
        return 'Unknown';
    }
  }
}
