export enum FeedlotPermissionsWorkOrder {
  WorkOrdersArrivalView = 'canViewArrivalWorkOrders',
  WorkOrdersArrivalEdit = 'canEditArrivalWorkOrders',
  WorkOrdersArrivalFinalize = 'canFinalizeArrivalWorkOrders',
  WorkOrdersLotTransferView = 'canViewWorkOrdersLotTransfers',
  WorkOrdersLotTransferEdit = 'canEditWorkOrdersLotTransfers',
  WorkOrdersLotTransferFinalize = 'canFinalizeWorkOrdersLotTransfers',
  WorkOrdersMovementView = 'canViewMovementWorkOrders',
  WorkOrdersMovementEdit = 'canEditMovementWorkOrders',
  WorkOrdersMovementFinalize = 'canFinalizeMovementWorkOrders',
  WorkOrdersRailView = 'canViewRailWorkOrders',
  WorkOrdersRailEdit = 'canEditRailWorkOrders',
  WorkOrdersRailFinalize = 'canFinalizeRailWorkOrders',
  WorkOrdersReHandlingView = 'canViewRehandlingWorkOrders',
  WorkOrdersReHandlingEdit = 'canEditRehandlingWorkOrders',
  WorkOrdersReHandlingFinalize = 'canFinalizeRehandlingWorkOrders',
  WorkOrdersShipmentView = 'canViewShipmentWorkOrders',
  WorkOrdersShipmentEdit = 'canEditShipmentWorkOrders',
  WorkOrdersShipmentFinalize = 'canFinalizeShipmentWorkOrders',
  WorkOrdersTransferOutView = 'canViewTransferOutWorkOrders',
  WorkOrdersTransferOutEdit = 'canEditTransferOutWorkOrders',
  WorkOrdersTransferOutFinalize = 'canFinalizeTransferOutWorkOrders',
  WorkOrdersTransferInView = 'canViewTransferInWorkOrders',
  WorkOrdersTransferInEdit = 'canEditTransferINWorkOrders',
  WorkOrdersTransferInFinalize = 'canFinalizeTransferINWorkOrders'
}
