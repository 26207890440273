<sersi-spin [spin]="!!(isLoading$ | async)">
  <ng-container *transloco="let t; read: scopedNamespace">
    <div class="animal-availability-dialog">
      <p class="flex align-items-center gap-1">
        @if (animalAvailabilityDisplayModel?.link) {
          {{ animalAvailabilityDisplayModel?.message }}
          @if (isDesktop) {
            <a
              pButton
              icon="pi pi-external-link"
              iconPos="right"
              class="p-button-link button-pristine button-link mt-0.5"
              [routerLink]=" animalAvailabilityDisplayModel!.link"
              [label]="animalAvailabilityDisplayModel!.type || ''"
              (click)="closeDialog()"
            ></a>
          } @else {
            <a
              pButton
              icon="pi pi-external-link"
              iconPos="right"
              href="{{ animalAvailabilityDisplayModel!.link }}"
              class="p-button-link button-pristine button-link mt-0.5"
              target="_blank"
              [label]="animalAvailabilityDisplayModel!.type || ''"
              (click)="closeDialog()"
            ></a>
          }
        } @else {
          {{ animalAvailabilityDisplayModel?.type }}
        }
      </p>

      <div class="container-footer-buttons">
        <button
          pButton
          type="button"
          [rounded]="true"
          [label]="t('cancel-button-label')"
          (click)="closeDialog()"
        ></button>
      </div>
    </div>
  </ng-container>
</sersi-spin>
