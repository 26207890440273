export enum LabourChargesTypes {
  None = 0,
  Arrival = 1,
  HealthIntervention = 2,
  Rehandling = 3,
  Treatment = 4,
  PostMoretm = 5,
  Rail = 6,
  Shipment = 7,
  Movement = 8,
  LotTransfer = 9,
  AnimalTransfer = 10,
  TransferIn = 11,
  AnimalAlert = 12,
  TransferOut = 13
}
