import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

import { DeviceType } from '@ifhms/models/feedlot';

import { UpcBaseDeviceService } from './upc-base-device.service';
import { RfidDataScanEvent } from '../interfaces';

@Injectable()
export class UpcRfidService extends UpcBaseDeviceService {
  private _rfidData = new Subject<RfidDataScanEvent>();
  rfidData$ = this._rfidData.asObservable();
  private rfidInitialized: boolean|null = null;

  constructor(injector: Injector) {
    super(DeviceType.Rfid,injector);
    // Backdoor for testing
    (<any>window).setRfId = (tagNumber: string): void => this.updateRfidData({ tagNumber });
  }

  async initDevice(): Promise<boolean> {
    if(!this.rfidInitialized) {
      this.rfidInitialized = await this.initConnection();
      if (this.rfidInitialized) await this.subscribeToTagScan();
    }
    return this.rfidInitialized;
  }

  triggerScanValue(tagNumber: string | null): void {
    if (!tagNumber) return;
    this.updateRfidData({ tagNumber });
  }

  private async subscribeToTagScan(): Promise<void> {
    this.hubConnection?.on('TagScanned', (data: RfidDataScanEvent) => {
      this.updateRfidData(data);
    });
  }

  private updateRfidData(data: RfidDataScanEvent): void {
    // Ignore any events for the background tabs
    if (document.hidden) return;
    this._rfidData.next(data);
  }
}
