export enum EventType {
  Arrival = 1,
  ReHandling = 2,
  Rail = 3,
  Treatment = 4,
  PostMortem = 5,
  Shipped = 6,
  Movement = 7,
  LotTransfer = 8,
  TransferIn = 9
}
