import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonAngularUtilsModule } from '@common/angular/utils';
import { CattleSelectorComponent } from '@ifhms/feedlot/front-end/shared/domain/formly/cattle-selector';
import { SortGroupComponent } from '@ifhms/feedlot/front-end/shared/domain/formly/sort-group';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyTypesEnum, FormlyWrappersEnum, sersiFormlyValidationConfig } from '@sersi/angular/formly/core';
import { defaultFieldClassnameExtension } from '@sersi/angular/formly/extensions';

// TYPES
import { AutoCompleteTypeComponent } from '@sersi/angular/formly/types/auto-complete';
import { BannerTypeComponent } from '@sersi/angular/formly/types/banner';
import { ButtonTypeComponent } from '@sersi/angular/formly/types/button';

// TYPES
import { CalenderTypeComponent } from '@sersi/angular/formly/types/calendar';
import { CalendarRangeTypeComponent } from '@sersi/angular/formly/types/calendar-range';
import { CheckboxTypeComponent } from '@sersi/angular/formly/types/checkbox';
import { ColorBoxTypeComponent } from '@sersi/angular/formly/types/color-box';
import { CurrencyTypeComponent } from '@sersi/angular/formly/types/currency';
import { EditorTypeComponent } from '@sersi/angular/formly/types/editor';
import { GridTypeComponent } from '@sersi/angular/formly/types/grid';
import { InfoIconTypeComponent } from '@sersi/angular/formly/types/info-icon';
import { NumberInputTypeComponent } from '@sersi/angular/formly/types/number-input';
import { PasswordTypeComponent } from '@sersi/angular/formly/types/password';
import { PhoneNumberTypeComponent } from '@sersi/angular/formly/types/phone-number';
import { RepeatSectionTypeComponent } from '@sersi/angular/formly/types/repeat-section';
import { SearchInputTypeComponent } from '@sersi/angular/formly/types/search-input';
import { SectionTitleTypeComponent } from '@sersi/angular/formly/types/section-title';
import { MultiSelectTypeComponent, SingleSelectTypeComponent } from '@sersi/angular/formly/types/select';
import { TextAreaTypeComponent } from '@sersi/angular/formly/types/text-area';
import { TextInputTypeComponent } from '@sersi/angular/formly/types/text-input';
import { TextReadonlyTypeComponent } from '@sersi/angular/formly/types/text-readonly';

// WRAPPERS
import {
  DefaultFormFieldWrapperComponent,
  DefaultHorizontalFormFieldWrapperComponent,
  FormFieldWithToggleWrapperComponent
} from '@sersi/angular/formly/wrappers/default';
import { SectionWrapperComponent } from '@sersi/angular/formly/wrappers/section';
import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { FeedlotFormlyTypesEnum } from './interfaces/formly-types.enum';
import { TranslateService } from '@common/angular/translation';
import { RangeSliderTypeComponent } from '@sersi/angular/formly/types/range-slider';
import { SwitchTypeComponent } from '@sersi/angular/formly/types/switch';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    RippleModule,
    TooltipModule,
    ReactiveFormsModule,
    TranslocoModule,
    SersiAngularUiCoreModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: FormlyWrappersEnum.DEFAULT_WRAPPER,
          component: DefaultFormFieldWrapperComponent
        },
        {
          name: FormlyWrappersEnum.DEFAULT_HORIZONTAL_WRAPPER,
          component: DefaultHorizontalFormFieldWrapperComponent
        },
        {
          name: FormlyWrappersEnum.FIELD_WITH_TOGGLE_WRAPPER,
          component: FormFieldWithToggleWrapperComponent
        },
        {
          name: FormlyWrappersEnum.DEFAULT_SERSI,
          component: DefaultFormFieldWrapperComponent
        },
        {
          name: FormlyWrappersEnum.SECTION,
          component: SectionWrapperComponent
        }
      ],
      types: [
        {
          name: FormlyTypesEnum.TEXT_INPUT,
          component: TextInputTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.COLOR_BOX,
          component: ColorBoxTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.NUMBER_INPUT,
          component: NumberInputTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.PASSWORD,
          component: PasswordTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.PHONE_NUMBER,
          component: PhoneNumberTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.RANGE_SLIDER,
          component: RangeSliderTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.SEARCH_INPUT,
          component: SearchInputTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.TEXT_READONLY,
          component: TextReadonlyTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.SINGLE_SELECT,
          component: SingleSelectTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.MULTI_SELECT,
          component: MultiSelectTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.CALENDAR_INPUT,
          component: CalenderTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.CALENDAR_RANGE_INPUT,
          component: CalendarRangeTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.CHECKBOX,
          component: CheckboxTypeComponent
        },
        {
          name: FormlyTypesEnum.TEXT_AREA_INPUT,
          component: TextAreaTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.EDITOR,
          component: EditorTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FeedlotFormlyTypesEnum.CATTLE_SELECTOR,
          component: CattleSelectorComponent,
          wrappers: [FormlyWrappersEnum.SECTION]
        },
        {
          name: FormlyTypesEnum.INFO_ICON,
          component: InfoIconTypeComponent
        },
        {
          name: FormlyTypesEnum.REPEAT_SECTION_TYPE,
          component: RepeatSectionTypeComponent
        },
        {
          name: FeedlotFormlyTypesEnum.SORT_GROUP,
          component: SortGroupComponent,
          wrappers: [FormlyWrappersEnum.SECTION]
        },
        {
          name: FormlyTypesEnum.SECTION_TITLE,
          component: SectionTitleTypeComponent
        },
        {
          name: FormlyTypesEnum.CURRENCY_INPUT,
          component: CurrencyTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.REPEATING_GRID_TYPE,
          component: GridTypeComponent
        },
        {
          name: FormlyTypesEnum.BUTTON,
          component: ButtonTypeComponent
        },
        {
          name: FormlyTypesEnum.AUTO_COMPLETE,
          component: AutoCompleteTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        },
        {
          name: FormlyTypesEnum.BANNER,
          component: BannerTypeComponent
        },
        {
          name: FormlyTypesEnum.SWITCH_INPUT,
          component: SwitchTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER]
        }
      ],
      extensions: [
        {
          name: 'default-classname',
          extension: defaultFieldClassnameExtension
        }
      ]
    }),
    CommonAngularUtilsModule
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: sersiFormlyValidationConfig,
      deps: [TranslateService]
    }
  ]
})
export class IfhmsFeedlotFrontEndWebCoreFormlyModule {
  constructor(
  @Optional()
  @SkipSelf()
    parentModule: IfhmsFeedlotFrontEndWebCoreFormlyModule
  ) {
    if (parentModule) {
      throw new Error(
        'IfhmsFeedlotFrontEndWebCoreFormlyModule is already loaded. Import only in AppModule'
      );
    }
  }
}
