<div class="switch_container">
    <p-inputSwitch
            (onChange)="onChange($event)"
            [falseValue]="props['falseValue']"
            [formControl]='formControl'
            [formlyAttributes]='field'
            [trueValue]="props['trueValue']"
    ></p-inputSwitch>
    <label *ngIf="props['withLabel'] === true"
           class="switch_label p-component"
    > {{label}} </label>
</div>