export enum FeedlotPermissionsEvent {
  EventsArrivalView = 'canViewArrivalEvents',
  EventsArrivalEdit = 'canEditArrivalEvents',
  EventsPostMortemView = 'canViewPostMortemEvents',
  EventsPostMortemEdit = 'canEditPostMortemEvents',
  EventsPostMortemComplete = 'canCompletePostMortemEvents',
  EventsRailView = 'canViewRailEvents',
  EventsRailEdit = 'canEditRailEvents',
  EventsReHandlingView = 'canViewRehandlingEvents',
  EventsReHandlingEdit = 'canEditRehandlingEvents',
  EventsTreatmentView = 'canViewTreatmentEvents',
  EventsTreatmentEdit = 'canEditTreatmentEvents',
  EventsTreatmentComplete = 'canCompleteTreatmentEvents',
  EventsTransferInView = 'canViewTransferInEvents',
  EventsTransferInEdit = 'canEditTransferInEvents'
}
