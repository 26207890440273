import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  TransferOutWorkOrderDto,
  TransferOutWorkOrderUpdateDto,
  WorkOrderTransferOutListDto,
  WorkOrderTransferOutListRequestDto,
  WorkOrderTransferOutStatus
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class TransferOutWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: WorkOrderTransferOutListRequestDto): Observable<WorkOrderTransferOutListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderTransferOutListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder`, {
      params
    });
  }

  getTransferOutWorkOrder(feedlotId: string, workOrderId: string): Observable<TransferOutWorkOrderDto> {
    return this.http.get<TransferOutWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}`);
  }

  update(feedlotId: string, transferOutWorkOrderUpdate: TransferOutWorkOrderUpdateDto): Observable<TransferOutWorkOrderDto> {
    return this.http.put<TransferOutWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder`, transferOutWorkOrderUpdate);
  }

  schedule(feedlotId: string, workOrderId: string): Observable<TransferOutWorkOrderDto> {
    return this.http.put<TransferOutWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}/Schedule`, null);
  }

  requestApproval(feedlotId: string, workOrderId: string, workEventDate: string): Observable<TransferOutWorkOrderDto> {
    return this.http.put<TransferOutWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}/RequestApproval`, { workEventDate });
  }

  generateReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/TransferOutWorkOrder`, options);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<TransferOutWorkOrderDto> {
    return this.http.put<TransferOutWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}/Finalize`, null);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<TransferOutWorkOrderDto> {
    return this.http.delete<TransferOutWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferOut/WorkOrder/${workOrderId}`);
  }

  private getRequestStatusName(status: WorkOrderTransferOutStatus): string {
    switch (status) {
      case WorkOrderTransferOutStatus.Scheduled:
        return 'Schedule';
      case WorkOrderTransferOutStatus.WaitingForApproval:
        return 'RequestApproval';
      case WorkOrderTransferOutStatus.Finalized:
        return 'Finalize';
      default:
        return 'Unknown';
    }
  }
}
