import {
  SortGroupOutcomeFilterBy,
  SortGroupOutcomeListItemDto,
  SortGroupOutcomeSortBy
} from '@ifhms/models/feedlot';

export const filterAndSortOutcomeList = (
  outcomeList: SortGroupOutcomeListItemDto[],
  filterBy: SortGroupOutcomeFilterBy,
  sortBy: SortGroupOutcomeSortBy
): SortGroupOutcomeListItemDto[] => {
  const list = outcomeList.filter((x) =>
    Object.entries(filterBy).every(([key, value]) => {
      if (key === 'status') {
        return value === undefined || x[key] === (value === 'true') || !x[key] === (value === 'false');
      }
      //@ts-ignore
      return !value || x[key] === value;
    })
  );

  switch (sortBy) {
    case SortGroupOutcomeSortBy.BreedAscending:
      list.sort((a, b) => a.breed.localeCompare(b.breed));
      break;
    case SortGroupOutcomeSortBy.BreedDescending:
      list.sort((a, b) => b.breed.localeCompare(a.breed));
      break;
    case SortGroupOutcomeSortBy.BreedTypeAscending:
      list.sort((a, b) => a.breedType.localeCompare(b.breedType));
      break;
    case SortGroupOutcomeSortBy.BreedTypeDescending:
      list.sort((a, b) => b.breedType.localeCompare(a.breedType));
      break;
    case SortGroupOutcomeSortBy.WeightAscending:
      list.sort((a, b) => a.weight.localeCompare(b.weight, undefined, { numeric: true }));
      break;
    case SortGroupOutcomeSortBy.WeightDescending:
      list.sort((a, b) => b.weight.localeCompare(a.weight, undefined, { numeric: true }));
      break;
    case SortGroupOutcomeSortBy.AgeAscending:
      list.sort((a, b) => a.age.localeCompare(b.age));
      break;
    case SortGroupOutcomeSortBy.AgeDescending:
      list.sort((a, b) => b.age.localeCompare(a.age));
      break;
    case SortGroupOutcomeSortBy.AgeClassAscending:
      list.sort((a, b) => a.ageClass.localeCompare(b.ageClass));
      break;
    case SortGroupOutcomeSortBy.AgeClassDescending:
      list.sort((a, b) => b.ageClass.localeCompare(a.ageClass));
      break;
    case SortGroupOutcomeSortBy.TemperatureAscending:
      list.sort((a, b) => a.temperature.localeCompare(b.temperature));
      break;
    case SortGroupOutcomeSortBy.TemperatureDescending:
      list.sort((a, b) => b.temperature.localeCompare(a.temperature));
      break;
    case SortGroupOutcomeSortBy.DCodeAscending:
      list.sort((a, b) => a.dentition.localeCompare(b.dentition));
      break;
    case SortGroupOutcomeSortBy.DCodeDescending:
      list.sort((a, b) => b.dentition.localeCompare(a.dentition));
      break;
    case SortGroupOutcomeSortBy.GenderAscending:
      list.sort((a, b) => a.gender.localeCompare(b.gender));
      break;
    case SortGroupOutcomeSortBy.GenderDescending:
      list.sort((a, b) => b.gender.localeCompare(a.gender));
      break;
    case SortGroupOutcomeSortBy.OutputGroupAscending:
      list.sort((a, b) => a.groupNumber - b.groupNumber);
      break;
    case SortGroupOutcomeSortBy.OutputGroupDescending:
      list.sort((a, b) => b.groupNumber - a.groupNumber);
      break;
    case SortGroupOutcomeSortBy.None:
    default:
      break;
  }

  return list;
};
