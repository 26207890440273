export enum WorkOrderBasePath {
  Arrival = 'arrival',
  LotTransfer ='lottransfer',
  Movement = 'movement',
  Rail = 'rail',
  ReHandling = 'rehandling',
  Shipment = 'shipment',
  TransferOut = 'transferout',
  TransferIn = 'transferin'
}
