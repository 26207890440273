import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  GoToEventDto,
  RehandlingWorkOrderDto,
  RehandlingWorkOrderUpdateDto,
  WorkOrderReHandlingListDto,
  WorkOrderReHandlingListRequestDto,
  WorkOrderReHandlingStatus
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class RehandlingWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  getList(feedlotId: string, request: WorkOrderReHandlingListRequestDto): Observable<WorkOrderReHandlingListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderReHandlingListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder`, {
      params
    });
  }

  getRehandlingWorkOrder(feedlotId: string, workOrderId: string): Observable<RehandlingWorkOrderDto> {
    return this.http.get<RehandlingWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}`);
  }

  getActiveEventId(feedlotId: string, workOrderId: string): Observable<GoToEventDto> {
    return this.http.get<GoToEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/GoToEvent`);
  }

  update(feedlotId: string, rehandlingWorkOrderUpdate: RehandlingWorkOrderUpdateDto): Observable<RehandlingWorkOrderDto> {
    return this.http.put<RehandlingWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder`, rehandlingWorkOrderUpdate);
  }

  updateStatus(feedlotId: string, workOrderId: string, status: WorkOrderReHandlingStatus): Observable<RehandlingWorkOrderDto> {
    const requestStatus = this.getRequestStatusName(status);
    return this.http.put<RehandlingWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/${requestStatus}`, null);
  }

  resetEvents(feedlotId: string, workOrderId: string): Observable<RehandlingWorkOrderDto> {
    return this.http.put<RehandlingWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/ResetEvents`, null);
  }

  generateWorkSheetReport(feedlotId : string, workOrderId : string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/RehandlingWorkOrderSheet`, options);
  }

  generateSummaryReport(feedlotId : string, workOrderId : string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/RehandlingWorkOrder`, options);
  }

  startGroupProcessing(feedlotId: string, workOrderId: string, workEventDate: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Process`, { workEventDate });
  }

  cancelGroupProcessing(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/CancelGroupProcess`, null);
  }

  complete(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Complete`, null);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Finalize`, null);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/Workorder/${workOrderId}`);
  }

  private getRequestStatusName(status: WorkOrderReHandlingStatus): string {
    switch (status) {
      case WorkOrderReHandlingStatus.Scheduled:
        return 'Schedule';
      case WorkOrderReHandlingStatus.InProgress:
        return 'RequestApproval';
      case WorkOrderReHandlingStatus.Finalized:
        return 'Finalize';
      default:
        return 'Unknown';
    }
  }
}
