import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

import { FeedlotDto } from '@ifhms/models/feedlot';
import { FeedlotService } from '@ifhms/common/angular/data-access/feedlot-api';
import { IS_DESKTOP } from '@common/angular/config';

import { FeedlotActions } from './feedlot.actions';

@Injectable({
  providedIn: 'root'
})
export class FeedlotEffects {

  constructor(
    protected actions$: Actions,
    private feedlotService: FeedlotService,
    private router: Router,
    @Optional() @Inject(IS_DESKTOP) private isDesktop: boolean
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedlotActions.get),
      exhaustMap((action) => {
        return this.feedlotService.get(action.id).pipe(
          map((feedlot: FeedlotDto) => {
            if (!feedlot) throw new Error();
            return FeedlotActions.getSuccess({ feedlot });
          }),
          catchError(() => of(FeedlotActions.error()))
        );
      })
    )
  );

  getBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedlotActions.getBySlug),
      exhaustMap((action) => {
        return this.feedlotService.getBySlug(action.slug).pipe(
          map((feedlot: FeedlotDto) => {
            if (!feedlot) throw new Error();
            return FeedlotActions.getSuccess({ feedlot });
          }),
          catchError(() => of(FeedlotActions.error()))
        );
      })
    )
  );

  feedlotLoadErr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedlotActions.error),
      tap(() => {
        const targetUrl = this.isDesktop ? '/login' : '/';
        void this.router.navigateByUrl(targetUrl);
      })
    ),
  { dispatch: false }
  );

}
