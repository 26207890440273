import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TransferInEventDto,
  TransferInEventFormTriggerParamsDto,
  TransferInEventListDto,
  TransferInEventListRequestDto,
  TransferInEventUpdateDto,
  TransferInWorkSheetSummary,
  EventAnimalCountDto,
  EventFormTriggerOutputDto,
  EventsListDto
} from '@ifhms/models/feedlot';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';
import { USE_DEFAULT_ERROR_HANDLER } from '@common/angular/error-handling';

@Injectable()
export class FeedlotEventsTransferInService {

  private context = new HttpContext().set(USE_DEFAULT_ERROR_HANDLER, false);

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  getList(feedlotId: string, request: TransferInEventListRequestDto): Observable<TransferInEventListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<TransferInEventListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/List`, {
      params
    });
  }

  getEvent(feedlotId: string, eventId: string, workOrderId: string): Observable<TransferInEventDto> {
    return this.http.get<TransferInEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Event/${eventId}/`);
  }

  getFormTrigger(feedlotId: string, workOrderId: string, request: TransferInEventFormTriggerParamsDto): Observable<EventFormTriggerOutputDto> {
    return this.http.put<EventFormTriggerOutputDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/EventFormTriggerOutput`, request);
  }

  getGoToEventList(feedlotId: string, workOrderId: string): Observable<EventsListDto> {
    return this.http.get<EventsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/GoToSelectList`);
  }

  getActiveEventId(feedlotId: string, workOrderId: string): Observable<TransferInEventDto> {
    return this.http.get<TransferInEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Events/TransferIn/WorkOrder/${workOrderId}/GoToEvent`);
  }

  getNextEventId(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Event/${eventId}/Next`);
  }

  getPreviousEventId(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Event/${eventId}/Back`);
  }

  updateEvent(feedlotId: string, eventId: string, workOrderId: string, eventUpdate: TransferInEventUpdateDto): Observable<TransferInEventDto> {
    return this.http.put<TransferInEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Event/${eventId}`,
      eventUpdate,
      { context: this.context }
    );
  }

  resetEvent(feedlotId: string, workOrderId: string, eventId: string): Observable<TransferInEventDto> {
    return this.http.put<TransferInEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Events/TransferIn/${eventId}/${workOrderId}/reset`, null);
  }

  createEvent(feedlotId: string, workOrderId: string, eventCreate: TransferInEventUpdateDto): Observable<TransferInEventDto> {
    return this.http.post<TransferInEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Create`,
      eventCreate,
      { context: this.context }
    );
  }

  getEventsAnimalCount(feedlotId: string, workOrderId: string): Observable<EventAnimalCountDto[]> {
    return this.http.get<EventAnimalCountDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/AnimalCountList`);
  }

  getWorkSheetSummary(feedlotId: string, workOrderId: string): Observable<TransferInWorkSheetSummary> {
    return this.http.get<TransferInWorkSheetSummary>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/WorkSheetSummary`);
  }

  deleteEvent(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/Feedlot/${feedlotId}/TransferIn/WorkOrder/${workOrderId}/Event/${eventId}/Delete`);
  }

  getAnimalCciaDob(feedlotId: string, nationalId: number): Observable<Date> {
    const params = new HttpParams()
      .set('nationalId', nationalId ?? '');

    return this.http.get<Date>(`${this.apiUrl}/Feedlot/${feedlotId}/Events/AnimalCciaDateOfBirth`, { params });
  }

}
