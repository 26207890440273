import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UpcApiFacade } from '@ifhms/common/angular/upc/shared';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { FormAlleyFieldComponent } from '@ifhms/common/angular/upc/gate-controller';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { untilDestroyed } from '@ngneat/until-destroy';
import { HubConnectionState } from '@microsoft/signalr';
import {
  FormlyTypesEnum,
  FormlyWrappersEnum
} from '@sersi/angular/formly/core';
import { ConnectionStatusLabelFormFieldComponent } from '../connection-status-label-form-field/connection-status-label-form-field.component';
import {
  DeviceConnectionStatusEnum,
  GateToAlleyMap,
  UpcSettingsDto
} from '@ifhms/models/feedlot';
import { UpcGateControllerService } from '@ifhms/common/angular/data-access/upc-api';

interface GateSettingsFormModel {
  settings: {
    name: string;
    numGates: number;
  };
  gateToAlleyMap: {
    [key: string]: string;
  };
}

@Component({
  selector: 'ifhms-upc-gate-settings-form',
  templateUrl: './upc-gate-settings-form.component.html',
  styleUrls: ['./upc-gate-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcGateSettingsFormComponent extends AbstractTranslationComponent {
  translationNamespace = 'components.upc-gate-settings-form';

  form = new FormGroup({});
  model: GateSettingsFormModel;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  upcSettings: UpcSettingsDto | null;
  gateControllerConnectionStatus$: Observable<string>;

  selectedAlleys = new BehaviorSubject<any>([]);
  constructor(
    private upcGateService: UpcGateControllerService,
    private cd: ChangeDetectorRef,
    private refDataFacade: ReferenceDataFacade,
    private upcApiFacade: UpcApiFacade
  ) {
    super();
  }

  protected override onTranslationInit(): void {
    this.getConnectionStatuses();
    this.getGateSettings();
    this.loadRefData();
  }

  protected override onTranslationReady(): void {
    this.getConnectionStatuses();
    this.getGateSettings();
    this.loadRefData();
  }

  private getConnectionStatuses(): void {
    this.gateControllerConnectionStatus$ =
      this.upcGateService.connectionState$.pipe(
        map((state) => {
          switch (state) {
            case HubConnectionState.Connected:
              return DeviceConnectionStatusEnum.Connected;
            case HubConnectionState.Disconnected:
              return DeviceConnectionStatusEnum.Disconnected;
            default:
              return DeviceConnectionStatusEnum.Disconnected;
          }
        })
      );
  }

  private getGateSettings(): void {
    this.upcGateService.deviceSettings$
      .pipe(
        filter((deviceStatusDto) => !!deviceStatusDto?.settings),
        untilDestroyed(this)
      )
      .subscribe((deviceStatusDto) => {
        this.model = {
          ...this.model,
          settings: {
            name: deviceStatusDto?.settings.name,
            numGates: deviceStatusDto?.settings.gateSettings.numGates
          }
        };
        this.initializeFields();
        this.cd.detectChanges();
      });
  }

  private initializeFields(): void {
    this.setFields(this.model.settings?.numGates);
    this.subscribeToUpcSettings();
  }

  private subscribeToUpcSettings(): void {
    this.upcApiFacade.upcSettings$
      .pipe(untilDestroyed(this))
      .subscribe((upcSettings) => {
        this.upcSettings = upcSettings;
        this.patchForm(upcSettings);
        this.cd.detectChanges();
      });
  }

  private patchForm(upcSettings: UpcSettingsDto | null): void {
    if (!upcSettings || !upcSettings.gateToAlleyMap) {
      return;
    }

    const gateGroup: Record<string, string> = {};
    const alleyIds: string[] = [];

    for (const mapObject of upcSettings.gateToAlleyMap) {
      gateGroup[`gate${mapObject.gateNumber}`] = mapObject.alleyId;
      alleyIds.push(mapObject.alleyId);
    }

    this.model = {
      ...this.model,
      gateToAlleyMap: gateGroup
    };

    this.selectedAlleys.next(alleyIds);
  }

  private setFields(numGates: number): void {
    this.fields = [
      {
        fieldGroupClassName: 'upc-gate-settings-form',
        fieldGroup: [
          this.setSettingsFields(),
          this.setAlley(),
          this.setGateHeaderGroup(),
          this.setGateGroup(numGates)
        ]
      }
    ];
  }

  private setGateHeaderGroup(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'gate-header-group',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      fieldGroup: [
        this.setGateControllerLabel(),
        this.setGateControllerConnectionStatus()
      ]
    };
  }

  private setGateControllerLabel(): FormlyFieldConfig {
    return {
      type: 'template',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      className: 'upc-gate-controller-label',
      template: `<h4>${this.getTranslation('gate-controller-header')}</h4>`
    };
  }

  private setGateControllerConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-gate-connection',
      templateOptions: {
        label: '',
        connectionStatus: this.gateControllerConnectionStatus$
      }
    };
  }

  private setGateGroup(numberOfGates: number): FormlyFieldConfig {
    const gateFields = Array.from({ length: numberOfGates }, (_, i) =>
      this.gateField(i + 1)
    );

    return <FormlyFieldConfig>{
      key: 'gateToAlleyMap',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      fieldGroupClassName: 'upc-gate-group',
      fieldGroup: gateFields
    };
  }

  private gateField(index: number): FormlyFieldConfig {
    return {
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'upc-gate-field',
      key: `gate${index}`,
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label: this.getTranslation('gate', { index: index }),
        placeholder: this.getTranslation('gate-field-placeholder'),
        items$: this.refDataFacade.alleys$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE_DESCRIPTION',
        excludeItems$: this.selectedAlleys,
        change: (): void => {
          this.updateAlleysSelection();
        }
      }
    };
  }

  private setGateToAlleySubmitButton(): FormlyFieldConfig {
    return {
      type: FormlyTypesEnum.BUTTON,
      className: 'upc-gate-to-alley-submit-button',
      props: {
        label$: this.getTranslation$('gate-to-alley-submit-button-label'),
        buttonIcon: 'pi pi-save',
        click: () => this.onUpdate()
      },
      expressions: {
        'templateOptions.disabled': () => this.form.pristine
      }
    };
  }

  private setAlley(): FormlyFieldConfig {
    return {
      type: FormAlleyFieldComponent,
      className: 'upc-alley',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label: this.getTranslation('alley'),
        placeholder: this.getTranslation('option-select')
      }
    };
  }

  private setSettingsFields(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'gate-control-settings-fields',
      fieldGroup: [
        {
          key: 'settings.name',
          className: 'gate-control-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label: this.getTranslation('gate-control-name')
          }
        },
        {
          key: 'settings.numGates',
          className: 'gate-control-numOfGates',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label: this.getTranslation('gate-control-numOfGates')
          }
        }
      ]
    };
  }

  private updateAlleysSelection(): void {
    const gateGroup =
      (this.form.value as GateSettingsFormModel).gateToAlleyMap || {};

    const newValue = Object.values(gateGroup).filter(Boolean);

    this.selectedAlleys.next(newValue);
  }

  private loadRefData(): void {
    this.refDataFacade.getAlleys();
  }

  onUpdate(): void {
    if (this.form && !this.form.pristine) {
      const formValue = this.form.value as GateSettingsFormModel;

      const gateGroup = formValue.gateToAlleyMap || {};

      const gateToAlleyMap: GateToAlleyMap[] = Object.entries(gateGroup)
        .filter(([key, value]) => value && key.startsWith('gate'))
        .map(([key, value]) => ({
          gateNumber: key.replace('gate', ''),
          alleyId: value as string
        }));

      const upcSettingsDto: Partial<UpcSettingsDto> = {
        ...this.upcSettings,
        gateToAlleyMap: gateToAlleyMap
      };

      this.upcApiFacade.updateUPCSettings(upcSettingsDto);
      this.form.markAsPristine();
    }
  }

  onReset(): void {
    this.upcApiFacade.resetGateController();
  }
}
